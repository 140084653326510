import React, { useState } from "react";
import { Button, TextField, Grid, Paper, Typography, Container, Box } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { backendURL } from "../../config";

function Login(props) {
	const { setUsername, setAuthentication, setEmailID } = props;
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loginError, setLoginError] = useState(null);
	const handleSubmit = async (event) => {
		event.preventDefault();

		let requestParams = {
			method: "post",
			url: backendURL + "login",
			headers: {
				"Content-Type": "application/json",
			},
			data: { email: email, password: password },
		};

		try {
			const authenticationResponse = await axios(requestParams);
			if (authenticationResponse.data) {
				if (authenticationResponse.data.authenticated) {
					setAuthentication(true);
					setEmailID(email);
					setUsername(authenticationResponse.data.username);
					navigate("/home");
				} else {
					setLoginError(authenticationResponse.data.message);
				}
			}
		} catch (error) {
			console.error("Error:", error);
			if (error.response) {
				console.log("Error Response Status:", error.response.status);
			}
		}
	};

	return (
		<Grid container component="main" sx={{ height: "80vh" }}>
			<Grid item xs={false} sm={2} md={3} />
			<Grid
				item
				xs={12}
				sm={8}
				md={6}
				component={Paper}
				elevation={6}
				square
				container
				alignItems="center"
				justifyContent="center"
				display="flex">
				<Container maxWidth="xs">
					<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
						<Typography component="h1" variant="h5">
							Sign In
						</Typography>
						<form noValidate onSubmit={handleSubmit} style={{ width: "100%", mt: 1 }}>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="email"
								label="Email Address"
								name="email"
								autoComplete="email"
								autoFocus
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								name="password"
								label="Password"
								type="password"
								id="password"
								autoComplete="current-password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							<span style={{ color: "red" }}>{loginError}</span>

							<Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2 }}>
								Sign In
							</Button>
							<Grid container>
								<Grid item xs>
									<Link to="/forgot-password" style={{ textDecoration: "none", color: "inherit" }}>
										Forgot password?
									</Link>
								</Grid>
								<Grid item>
									<Link to="/register" style={{ textDecoration: "none", color: "inherit" }}>
										{"Don't have an account? Sign Up"}
									</Link>
								</Grid>
							</Grid>
						</form>
					</Box>
				</Container>
			</Grid>
			<Grid item xs={false} sm={2} md={3} />
		</Grid>
	);
}

export default Login;
