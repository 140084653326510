import React, { useState } from "react";
import { Button, TextField, Grid, Paper, Typography, Container, Box } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { backendURL } from "../../config";

function Register(props) {
	const { setUsername, setAuthentication, setEmailID } = props;
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [uname, setUname] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [usernameError, setUsernameError] = useState(null);
	const [emailError, setEmailError] = useState(null);
	const [passwordError, setPasswordError] = useState(null);
	const [confirmPasswordError, setConfirmPasswordError] = useState(null);

	const handleOnblur = (event) => {
		event.preventDefault();
		const tagID = event.target.id;
		if (tagID === "uname") {
			if (uname.length < 8) {
				setUsernameError("Username should be atleasr 8 characters");
			} else {
				setUsernameError(null);
			}
		} else if (tagID === "email") {
			const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			if (!re.test(String(email).toLowerCase())) {
				setEmailError("Please enter a valid Email");
			} else {
				setEmailError(null);
			}
		} else if (tagID === "password") {
			const re = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_]).{8,}$/;

			if (password.length < 8) {
				setPasswordError("Password should be atleast 8 characters");
			} else if (re.test(password)) {
				setPasswordError(null);
			} else {
				setPasswordError(
					"Password should contains at least 1 capital letter, 1 number, and 1 special character "
				);
			}
		} else if (tagID === "confirmPassword") {
			if (password !== confirmPassword) {
				setConfirmPasswordError("Passwords Does not match");
			} else {
				setConfirmPasswordError(null);
			}
		}
	};
	const handleSubmit = async (event) => {
		event.preventDefault();
		if(usernameError === null && emailError === null && passwordError === null && confirmPasswordError===null){
			let requestParams = {
				method: "post",
				url: backendURL + "checkuserexists",
				headers: {
					"Content-Type": "application/json",
				},
				data: { email: email, username: uname },
			};

			try {
				const checkUserResponse = await axios(requestParams);
				if (checkUserResponse.data) {
					if (checkUserResponse.data.userExists) {
						alert(checkUserResponse.data.message);
					} else {
						let requestParams = {
							method: "post",
							url: backendURL + "register",
							headers: {
								"Content-Type": "application/json",
							},
							data: { email: email, username: uname, password: password },
						};

						try {
							const authenticationResponse = await axios(requestParams);
							if (authenticationResponse.data) {
								if (authenticationResponse.data.success) {
									setUsername(uname);
									setEmailID(email);
									setAuthentication(true);
									navigate("/questionnaire");
								} else {
									setConfirmPassword(authenticationResponse.data.message);
								}
							}
						} catch (error) {
							console.error("Error:", error);
							if (error.response) {
								console.log("Error Response Status:", error.response.status);
							}
						}
					}
				}
			} catch (error) {
				console.error("Error:", error);
				if (error.response) {
					console.log("Error Response Status:", error.response.status);
				}
			}
		}
	};

	return (
		<Grid container component="main" sx={{ height: "80vh" }}>
			<Grid item xs={false} sm={2} md={3} />
			<Grid
				item
				xs={12}
				sm={8}
				md={6}
				component={Paper}
				elevation={6}
				square
				container
				alignItems="center"
				justifyContent="center"
				display="flex">
				<Container maxWidth="xs">
					<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
						<Typography component="h1" variant="h5">
							Sign Up
						</Typography>
						<form noValidate onSubmit={handleSubmit} style={{ width: "100%", mt: 1 }}>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="uname"
								label="Username "
								name="uname"
								autoComplete="username"
								autoFocus
								value={uname}
								onChange={(e) => setUname(e.target.value)}
								onBlur={handleOnblur}
							/>
							<span style={{ color: "red" }}>{usernameError}</span>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="email"
								label="Email Address"
								name="email"
								autoComplete="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								onBlur={handleOnblur}
							/>
							<span style={{ color: "red" }}>{emailError}</span>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								name="password"
								label="Password"
								type="password"
								id="password"
								autoComplete="current-password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								onBlur={handleOnblur}
							/>
							<span style={{ color: "red" }}>{passwordError}</span>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								name="confirmPassword"
								label="Confirm Password"
								type="password"
								id="confirmPassword"
								value={confirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value)}
								onBlur={handleOnblur}
							/>
							<span style={{ color: "red" }}>{confirmPasswordError}</span>
							<Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2 }}>
								Sign Up
							</Button>
							<Grid container>
								<Grid item xs>
									<Link to="/login" style={{ textDecoration: "none", color: "inherit" }}>
										Already have an account? Sign in
									</Link>
								</Grid>
							</Grid>
						</form>
					</Box>
				</Container>
			</Grid>
			<Grid item xs={false} sm={2} md={3} />
		</Grid>
	);
}

export default Register;
