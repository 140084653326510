import React from "react";
import { TextField, Slider, Typography, Box } from "@mui/material";

const NumberInput = ({ question, onChange, value }) => {
    const handleSliderChange = (event, newValue) => {
        onChange(newValue.toString());
    };

    const handleTextChange = (event) => {
        onChange(event.target.value);
    };

    const isExperience = /years of (work )?experience/i.test(question.Question);

    return (
        <Box display="flex" flexDirection="column" alignItems="center" width="100%">
            <Typography gutterBottom>{question.Question}</Typography>
            {isExperience ? (
                <Slider
                    value={parseInt(value, 10) || 0}
                    onChange={handleSliderChange}
                    aria-labelledby="input-slider"
                    min={0}
                    max={25}
                    valueLabelDisplay="auto"
                    style={{ width: '100%' }}
                />
            ) : (
                <TextField
                    label={question.Question}
                    type="number"
                    variant="outlined"
                    fullWidth
                    value={value || ""}
                    onChange={handleTextChange}
                    InputProps={{ inputProps: { min: 0, type: "number" } }}
                />
            )}
        </Box>
    );
};

export default NumberInput;
