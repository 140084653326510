import React from "react";
import { TextField } from "@mui/material";

const Text = ({ question, value, onChange }) => {
    const handleInputChange = (event) => {
        onChange(event.target.value);
    };

    return (
        <TextField
            fullWidth
            variant="outlined"
            label={question.Question}
            value={value || ""}
            onChange={handleInputChange}
            margin="normal"
            required
        />
    );
};

export default Text;
