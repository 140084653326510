import React from "react";
import { AppBar, Toolbar, Typography, Button, useMediaQuery, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import Brightness4Icon from "@mui/icons-material/Brightness4"; 
import { styled, useTheme } from "@mui/system";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
	flexGrow: 1,
	"& .MuiToolbar-root": {
		justifyContent: "space-between",
	},
	"& .MuiButton-root": {
		color: "inherit", 
	},
	"& .MuiTypography-h6": {
		flexGrow: 1,
	},
	"& a": {
		textDecoration: "none",
		color: "inherit",
	},
}));

function Header({ toggleTheme }) {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<StyledAppBar position="static">
			<Toolbar>
				<Typography variant="h6">Joburst</Typography>
				<IconButton onClick={toggleTheme} color="inherit">
					<Brightness4Icon />
				</IconButton>
				<Link to="/home">
					<Button>Home</Button>
				</Link>
				<Link to="/dashboard">
					<Button>Dashboard</Button>
				</Link>
				<Link to="/profile">
					<Button>Profile</Button>
				</Link>
				<Link to="/login">
					<Button>Login</Button>
				</Link>
				<Link to="/contact">
					<Button>Contact Us</Button>
				</Link>
			</Toolbar>
		</StyledAppBar>
	);
}

export default Header;
