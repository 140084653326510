import React, { useState } from "react";
import { Typography, Paper, Container, Button, TextField } from "@mui/material";
import { styled } from "@mui/system";

const StyledContainer = styled(Container)(({ theme }) => ({
	flexGrow: 1,
	padding: theme.spacing(3),
	display: "flex", 
	flexDirection: "column",
	alignItems: "center",
	"& .MuiPaper-root": {
		padding: theme.spacing(10),
		textAlign: "center",
		color: theme.palette.text.secondary,
		marginBottom: theme.spacing(10),
		borderRadius: theme.spacing(10),
		boxShadow: theme.shadows[15]
	},
	"& .MuiTextField-root": {
		margin: theme.spacing(1),
		width: "100%"
	},
	"& .MuiButton-root": {
		margin: theme.spacing(4),
	},
}));

function Profile() {
	const [profile, setProfile] = useState({
		username: "JohnDoe",
		email: "john.doe@example.com",
	});

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setProfile((prevProfile) => ({
			...prevProfile,
			[name]: value,
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		alert("Profile updated successfully!");
	};

	return (
		<StyledContainer maxWidth="md">
			<Paper elevation={3}>
				<Typography variant="h5" gutterBottom>
					Manage Your Profile
				</Typography>
				<form noValidate autoComplete="off" onSubmit={handleSubmit}>
					<TextField
						name="username"
						label="Username"
						value={profile.username}
						onChange={handleInputChange}
						variant="outlined"
					/>
					<TextField
						name="email"
						label="Email"
						value={profile.email}
						onChange={handleInputChange}
						variant="outlined"
					/>
					<Button type="submit" variant="contained" color="primary">
						Update Profile
					</Button>
				</form>
			</Paper>
		</StyledContainer>
	);
}

export default Profile;
