import React from 'react';
import { Typography, Container } from '@mui/material';
import { styled } from '@mui/system';

const StyledFooter = styled('footer')(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: 'auto',
  backgroundColor: theme.palette.background.paper,
  textAlign: 'center',
}));

function Footer() {
  return (
    <StyledFooter>
      <Container maxWidth="sm">
        <Typography variant="body1">Job Application Automation Tool</Typography>
        <Typography variant="body2" color="text.secondary">
          © {new Date().getFullYear()} Job Automation, Inc.
        </Typography>
      </Container>
    </StyledFooter>
  );
}

export default Footer;
