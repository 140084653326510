import React, { useEffect, useState } from "react";
import { Button, Typography, Grid, Container, Card, Box, Avatar } from "@mui/material";
import { styled } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { mongoDBURL } from "../../config";

const StyledContainer = styled(Container)(({ theme }) => ({
    padding: theme.spacing(4),
}));

const ProfileCard = styled(Card)(({ theme }) => ({
    textAlign: "center",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
}));

const JobRoleCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
}));

const StatCard = styled(Card)(({ theme }) => ({
    textAlign: "center",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
}));

const HeaderBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
}));

const Dashboard = (props) => {
    const { username, authenticated } = props;
    const navigate = useNavigate();
    const [latestJobs, setLatestJobs] = useState([]);
    const [packageStats, setPackageStats] = useState({});

    useEffect(() => {
        if (!authenticated || username === "") {
            navigate("/login");
        } else
         {
            fetchLatestJobs();
            fetchPackageStats();
        }
    }, [authenticated, username, navigate]);

    const fetchLatestJobs = async () => {
        try {
            const requestParams = {
                method: "post",
                url: mongoDBURL+"getdata",
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    collectionName: username,
                    filter: {
                        "JobsApplied": { "$exists": true }
                    }
                },
            };
            const jobsAppliedResponse = await axios(requestParams);
    
            if (jobsAppliedResponse.data.data.length > 0) {
                const jobsAppliedData = jobsAppliedResponse.data.data[0].JobsApplied;
                // Sort and limit in the frontend
                const sortedJobs = jobsAppliedData.sort((a, b) => new Date(b.appliedDate) - new Date(a.appliedDate));
                const limitedJobs = sortedJobs.slice(0, 5);
                setLatestJobs(limitedJobs);
            }
        } catch (error) {
            console.error("Failed to fetch latest jobs applied", error);
        }
    };
    
    const fetchPackageStats = async () => {
        try {
            const requestParams = {
                method: "post",
                url: mongoDBURL+"getdata",
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    collectionName: "PackageStats",
                    filter: { username: username },
                },
            };
            const packageStatsResponse = await axios(requestParams);
            if (packageStatsResponse.data.data.length > 0) {
                setPackageStats(packageStatsResponse.data.data[0]);
            }
        } catch (error) {
            console.error("Failed to fetch package stats", error);
        }
    };
    


    return (
        <StyledContainer maxWidth="lg">
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h4">Welcome to {username}'s Dashboard</Typography>
                </Grid>

                <Grid item xs={12} md={8}>
                    <JobRoleCard>
                        <HeaderBox>
                            <Typography variant="h6">Latest 5 Jobs Applied</Typography>
                            <Button variant="contained" color="primary" component={Link} to="/jobhistory">
                                View All Jobs Applied
                            </Button>
                        </HeaderBox>
                        <Grid container spacing={2}>
                            {latestJobs.map((job, index) => (
                                <Grid item xs={12} key={index}>
                                    <Typography variant="subtitle1">{`${job.title} - ${job.company} (${job.location})`}</Typography>
                                </Grid>
                            ))}
                        </Grid>
                    </JobRoleCard>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <StatCard>
                                <Typography variant="h6">Your Package Stats</Typography>
                                <Typography variant="body2">Renews on {packageStats.renewalDate}</Typography>
                                <Box my={2}>
                                    <Typography variant="h4">{packageStats.jobsApplied}</Typography>
                                    <Typography variant="body2">Auto Jobs (Paid) Applied</Typography>
                                </Box>
                            </StatCard>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <StatCard>
                                <Box my={2}>
                                    <Typography variant="h4">{packageStats.totalJobsViewed}</Typography>
                                    <Typography variant="body2">Total Jobs Viewed</Typography>
                                </Box>
                            </StatCard>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={4}>
                    <ProfileCard>
                        <Box mb={2} textAlign="center">
                            <Avatar alt={username} src="/profile.jpg" sx={{ width: 100, height: 100, margin: "auto" }} />
                            <Typography  variant="h5" mt={2}>{username}</Typography>
                        </Box>
                        <Box mt={2} mb={1}>
                            <Button variant="contained" fullWidth component={Link} to="/profile" sx={{ mb: 1 }}>
                                View Profile
                            </Button>
                            <Button variant="contained" fullWidth component={Link} to="/personal-preferences" sx={{ mb: 1 }}>
                                Personal Preferences
                            </Button>
                            <Button variant="contained" fullWidth component={Link} to="/subscriptions" sx={{ mb: 1 }}>
                                Subscriptions
                            </Button>
                            <Button variant="contained" fullWidth component={Link} to="/create-job-role">
                                Create Job Role
                            </Button>
                        </Box>
                    </ProfileCard>
                </Grid>
            </Grid>
        </StyledContainer>
    );
};

export default Dashboard;
