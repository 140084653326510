import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import getTheme from "./theme";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Login from "./components/authentication/Login";
import Register from "./components/authentication/Register";
import Profile from "./components/profile/Profile";
import BasicQuestionnaire from "./components/questionnaire/BasicQuestionnaire";
import PreferenceQuestionnaire from "./components/questionnaire/PreferenceQuestionnaire";
import JobRelatedQuestionnaire from "./components/questionnaire/JobRelatedQuestionnaire";
import Home from "./components/home/Home";
import Dashboard from "./components/dashboard/Dashboard";
import Applyjobs from "./components/applyjob/Applybutton";
import { styled } from "@mui/system";
const AppContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	minHeight: "100vh",
}));

const ContentContainer = styled("main")(({ theme }) => ({
	flexGrow: 0,
}));

function App() {
	const [mode, setMode] = useState("light");
	const [username, setUsername] = useState("");
	const [emailId, setEmailID] = useState("");
	const [authenticated, setAuthentication] = useState(false);

	const toggleTheme = () => {
		setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
	};

	return (
		<AppContainer>
			<ThemeProvider theme={getTheme(mode)}>
				<Router>
					<ContentContainer>
						<Header toggleTheme={toggleTheme} />
						<Routes>
							<Route path="/home" element={<Home username={username} authenticated={authenticated} />} />
							<Route
								path="/login"
								element={
									<Login
										setUsername={setUsername}
										setAuthentication={setAuthentication}
										setEmailID={setEmailID}
									/>
								}
							/>
							<Route
								path="/register"
								element={
									<Register
										setUsername={setUsername}
										setAuthentication={setAuthentication}
										setEmailID={setEmailID}
									/>
								}
							/>
							<Route
								path="/dashboard"
								element={<Dashboard username={username} authenticated={authenticated} />}
							/>
							<Route
								path="/profile"
								element={<Profile username={username} authenticated={authenticated} />}
							/>
							<Route
								path="/questionnaire"
								element={<BasicQuestionnaire username={username} authenticated={authenticated} />}
							/>
							<Route
								path="/preferences"
								element={<PreferenceQuestionnaire username={username} authenticated={authenticated} />}
							/>
							<Route
								path="/applyjobs"
								element={
									<Applyjobs username={username} authenticated={authenticated} emailId={emailId} />
								}
							/>
							<Route
								path="/techquestionnaire"
								element={
									<JobRelatedQuestionnaire username={username} authenticated={authenticated} emailId={emailId} />
								}
							/>

							{/* <Route path="*" element={<Login setUsername={setUsername} setAuthentication={setAuthentication}  />} /> */}
						</Routes>
					</ContentContainer>
					<Footer />
				</Router>
			</ThemeProvider>
		</AppContainer>
	);
}

export default App;
