import React, { useState, useEffect } from "react";
import { Button, Typography, Paper, styled, Grid } from "@mui/material";
import axios from "axios";
import Text from "../questionTypes/Text";
import Select from "../questionTypes/Select";
import NumberInput from "../questionTypes/Number";
import MultipleAnswers from "../questionTypes/MultipleAnswers";
import { mongoDBURL } from "../../config";
import { useNavigate } from "react-router-dom";

const StyledPaper = styled(Paper)(({ theme }) => ({
	flexGrow: 1,
	padding: theme.spacing(4),
	textAlign: "center",
	backgroundColor: theme.palette.background.paper,
	boxShadow: theme.shadows[15],
	borderRadius: theme.spacing(0),
	marginTop: theme.spacing(4),
	width: "100%",
	maxWidth: "1200px",
	margin: "auto",
}));

const StyledButton = styled(Button)(({ theme }) => ({
	marginTop: theme.spacing(3),
	padding: theme.spacing(1.5),
	fontSize: theme.typography.fontSize + 1,
	borderRadius: theme.shape.borderRadius,
	backgroundColor: theme.palette.primary.main,
	"&:hover": {
		backgroundColor: theme.palette.primary.dark,
	},
}));

function BasicQuestionnaire(props) {
	const { username, authenticated } = props;
	const [questions, setQuestions] = useState([]);
	const [answers, setAnswers] = useState({});
	const navigate = useNavigate();

	useEffect(() => {
		if (!authenticated || username === "") {
			navigate("/login");
		}
	}, [authenticated, username, navigate]);

	useEffect(() => {
		const fetchData = async () => {
			const initialAnswers = {};
			try {
				let requestParams = {
					method: "post",
					url: mongoDBURL + "getdata",
					headers: {
						"Content-Type": "application/json",
					},
					data: {
						collectionName: "BasicQuestionnaire",
						filter: {},
					},
				};
				const questionResponse = await axios(requestParams);
				if (questionResponse.data.data && questionResponse.data.data.length > 0) {
					const BasicQuestionnaire = questionResponse.data.data;
					setQuestions(BasicQuestionnaire);

					BasicQuestionnaire.forEach((q) => {
						initialAnswers[q.Question] = { answer: "", questionText: q.Question };
					});
					setAnswers(initialAnswers);
				}

				if (username) {
					requestParams = {
						method: "post",
						url: mongoDBURL + "getdata",
						headers: {
							"Content-Type": "application/json",
						},
						data: {
							collectionName: username,
							filter: { BasicQuestionnaire: { "$exists": true } },
						},
					};
					const answerResponse = await axios(requestParams);

					if (answerResponse.data && answerResponse.data.data.length > 0) {
						const userAnswers = answerResponse.data.data[0].BasicQuestionnaire;
						const updatedAnswers = { ...initialAnswers };

						Object.keys(userAnswers).forEach((key) => {
							if (updatedAnswers[key]) {
								updatedAnswers[key].answer = userAnswers[key];
							}
						});

						setAnswers(updatedAnswers);
					}
				}
			} catch (error) {
				console.error("Failed to fetch data", error);
			}
		};

		fetchData();
	},[username] );

	const handleInputChange = (questionText, value) => {
		setAnswers((prevAnswers) => ({
			...prevAnswers,
			[questionText]: { ...prevAnswers[questionText], answer: value },
		}));
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		const formattedAnswers = Object.keys(answers).reduce((acc, key) => {
			acc[key] = answers[key].answer || "";
			return acc;
		}, {});

		try {
			let requestParams = {
				method: "post",
				url: mongoDBURL + "deletedata",
				headers: {
					"Content-Type": "application/json",
				},
				data: {
					collectionName: username,
					filter: { BasicQuestionnaire: { "$exists": true } },
				},
			};
			const deletedataResponse = await axios(requestParams);
			if (deletedataResponse.data.success) {
				requestParams = {
					method: "post",
					url: mongoDBURL + "pushdata",
					headers: {
						"Content-Type": "application/json",
					},
					data: {
						collectionName: username,
						data: [{ BasicQuestionnaire: formattedAnswers }],
					},
				};
				const pushDataResponse = await axios(requestParams);
				if (pushDataResponse.data.success) {
					navigate("/preferences");
				} else {
					alert(pushDataResponse.data.message);
				}
			} else {
				alert(deletedataResponse.data.message);
			}
		} catch (error) {
			console.error("Failed to save answers", error);
		}
	};

	const renderQuestionsByType = (type) => {
		return questions
			.filter((question) => question["Question Type"] === type)
			.map((question) => {
				const questionData = answers[question.Question] || { answer: "" };
				switch (type) {
					case "text":
						return (
							<Grid item xs={12} sm={6} key={question.Question}>
								<Text
									question={question}
									onChange={(value) => handleInputChange(question.Question, value)}
									value={questionData.answer}
									
								/>
							</Grid>
						);
					case "select":
						return (
							<Grid item xs={12} sm={6} key={question.Question}>
								<Select
									question={question}
									onChange={(value) => handleInputChange(question.Question, value)}
									value={questionData.answer}
								/>
							</Grid>
						);
					case "number":
						return (
							<Grid item xs={12} sm={6} key={question.Question}>
								<NumberInput
									question={question}
									onChange={(value) => handleInputChange(question.Question, value)}
									value={questionData.answer}
								/>
							</Grid>
						);
					case "multiple answers":
						return (
							<Grid item xs={12} sm={6} key={question.Question}>
								<MultipleAnswers
									question={question}
									onChange={(value) => handleInputChange(question.Question, value)}
									value={questionData.answer}
								/>
							</Grid>
						);
					default:
						return null;
				}
			});
	};

	return (
		<StyledPaper elevation={3}>
			<Typography variant="h5" gutterBottom>
				Fill Out this Basic Questionnaire
			</Typography>
			<form noValidate autoComplete="off" onSubmit={handleSubmit}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="h6" gutterBottom></Typography>
					</Grid>
					{renderQuestionsByType("text")}
					{renderQuestionsByType("select")}
					{renderQuestionsByType("number")}
					{renderQuestionsByType("multiple answers")}
				</Grid>
				<StyledButton type="submit" variant="contained">
					Submit Answers
				</StyledButton>
			</form>
		</StyledPaper>
	);
}

export default BasicQuestionnaire;