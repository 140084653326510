import { createTheme } from '@mui/material/styles';

const getTheme = (mode) => createTheme({
  palette: {
    mode, // dynamically set the mode
    primary: {
      main: mode === 'dark' ? '#2979ff' : '#2979ff', // Soothing blue in both modes
    },
    secondary: {
      main: mode === 'dark' ? '#66bb6a' : '#66bb6a', // Harmonious green in both modes
    },
    error: {
      main: '#ff4444',
    },
    background: {
      default: mode === 'dark' ? '#121212' : '#ffffff', // White for light mode and near black for dark mode
      paper: mode === 'dark' ? '#333333' : '#f5f5f5', // Dark paper for dark mode and lighter for light mode
    },
    text: {
      primary: mode === 'dark' ? '#ffffff' : '#333333', // White text on dark background and dark text on light background
      secondary: mode === 'dark' ? '#bbbbbb' : '#555555',
    }
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    button: {
      textTransform: 'none'
    },
    h5: {
      fontWeight: 700, // Making headers bold
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8, // Rounded corners for buttons
          boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)' // Subtle shadow for buttons
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderRadius: '8px', // Rounded corners for text fields
          }
        }
      }
    }
  }
});

export default getTheme;
