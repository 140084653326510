import React, { useEffect } from "react";
import {
	Typography,
	Grid,
	Container,
	Card,
	CardActionArea,
	CardMedia,
	CardContent,
	Snackbar,
	Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import axios from "axios";
import { backendURL } from "../../config";

const StyledContainer = styled(Container)(({ theme }) => ({
	flexGrow: 1,
	padding: theme.spacing(3),
	"& .MuiCard-root": {
		margin: theme.spacing(1),
	},
	"& .MuiTypography-h4": {
		marginBottom: theme.spacing(2),
	},
}));

function Applyjobs(props) {
	const { username, authenticated, emailId } = props;
	const navigate = useNavigate();
	const [openSnackbar, setOpenSnackbar] = React.useState(false);
	const [snackbarMessage, setSnackbarMessage] = React.useState("");

	useEffect(() => {
		if (!authenticated || username === "") {
			navigate("/login");
		}
	}, [authenticated, username, navigate]);

	const handleCardClick = async (jobBoard) => {
		try {
			const requestParams = {
				method: "post",
				url: backendURL + "sendotp",
				headers: {
					"Content-Type": "application/json",
				},
				data: {
					username: username,
					emailId: emailId,
					jobBoard: jobBoard,
				},
			};
			const jobsAppliedResponse = await axios(requestParams);

			if (jobsAppliedResponse.data.success) {
				setSnackbarMessage("An OTP has been sent to your email");
				setOpenSnackbar(true);
			} else {
				setSnackbarMessage("Failed to send OTP");
				setOpenSnackbar(true);
			}
		} catch (error) {
			setSnackbarMessage("Error sending OTP");
			setOpenSnackbar(true);
			console.error("Error sending OTP:", error);
		}
	};

	const handleSnackbarClose = () => {
		setOpenSnackbar(false);
	};

	return (
		<StyledContainer maxWidth="lg">
			<Grid container spacing={3}>
				<div id="username" hidden>
					{username}
				</div>
				<div id="email" hidden>
					{emailId}
				</div>
				<Grid item xs={12} sm={6}>
					<Card>
						<CardActionArea onClick={() => handleCardClick("LinkedIn")}>
							<CardMedia
								component="img"
								alt="LinkedIn"
								height="140"
								image="/images/LinkedIn.jpg"
								title="LinkedIn"
							/>
							<CardContent>
								<Typography gutterBottom variant="h5" component="div">
									LinkedIn
								</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>

				<Grid item xs={12} sm={6}>
					<Card>
						<CardActionArea onClick={() => handleCardClick("Dice")}>
							<CardMedia component="img" alt="Dice" height="140" image="/images/Dice.jpg" title="Dice" />
							<CardContent>
								<Typography gutterBottom variant="h5" component="div">
									Dice
								</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>

				<Grid item xs={12} sm={6}>
					<Card>
						<CardActionArea onClick={() => handleCardClick("HandShake")}>
							<CardMedia
								component="img"
								alt="HandShake"
								height="140"
								image="/images/handshake.jpg"
								title="HandShake"
							/>
							<CardContent>
								<Typography gutterBottom variant="h5" component="div">
									HandShake
								</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Card>
						<CardActionArea onClick={() => handleCardClick("Indeed")}>
							<CardMedia
								component="img"
								alt="Indeed"
								height="140"
								image="/images/indeed.jpg"
								title="Indeed"
							/>
							<CardContent>
								<Typography gutterBottom variant="h5" component="div">
									Indeed
								</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>
			</Grid>
			<Snackbar open={openSnackbar} onClose={handleSnackbarClose}>
				<Alert onClose={handleSnackbarClose} severity="success" sx={{ width: "100%" }}>
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</StyledContainer>
	);
}

export default Applyjobs;
