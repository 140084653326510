import React, { useEffect } from "react";
import { Typography, Grid, Container, Card, CardActionArea, CardMedia, CardContent } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "@mui/system";

const StyledContainer = styled(Container)(({ theme }) => ({
	flexGrow: 1,
	padding: theme.spacing(3),
	"& .MuiCard-root": {
		margin: theme.spacing(1),
	},
	"& .MuiTypography-h4": {
		marginBottom: theme.spacing(2),
	},
}));

function Home(props) {
	const { username, authenticated } = props;
	const navigate = useNavigate();
	useEffect(() => {
		if (!authenticated || username === "") {
			navigate("/login");
		}
	}, [authenticated, username, navigate]);

	return (
		<StyledContainer maxWidth="lg">
			<Grid container spacing={3}>
				<Grid item xs={12} sm={12}>
					<Typography variant="h5" component="div">
						Welcome {username}
					</Typography>
				</Grid>

				<Grid item xs={12} sm={6}>
					<Card>
						<CardActionArea component={Link} to="/dashboard">
							<CardMedia
								component="img"
								alt="dashboard"
								height="140"
								image="/images/dashboard.jpg"
								title="Profile"
							/>
							<CardContent>
								<Typography gutterBottom variant="h5" component="div">
									Dashboard
								</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>

				<Grid item xs={12} sm={6}>
					<Card>
						<CardActionArea component={Link} to="/preferences">
							<CardMedia
								component="img"
								alt="Preferences"
								height="140"
								image="/images/Preferences.jpg" 
								title="Preferences"
							/>
							<CardContent>
								<Typography gutterBottom variant="h5" component="div">
									Preferences
								</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Card>
						<CardActionArea component={Link} to="/questionnaire">
							<CardMedia
								component="img"
								alt="Questionnaire"
								height="140"
								image="/images/questionnaire.jpg"
								title="Questionnaire"
							/>
							<CardContent>
								<Typography gutterBottom variant="h5" component="div">
									Questionnaire
								</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Card>
						<CardActionArea component={Link} to="/techquestionnaire">
							<CardMedia
								component="img"
								alt="Job Related Question"
								height="140"
								image="/images/jobquestion.jpg"
								title="Job Related Question"
							/>
							<CardContent>
								<Typography gutterBottom variant="h5" component="div">
									Job Related Question
								</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Card>
						<CardActionArea component={Link} to="/applyjobs">
							<CardMedia
								component="img"
								alt="Apply for Jobs"
								height="140"
								image="/images/apply-jobs.jpg" 
								title="Apply for Jobs"
							/>
							<CardContent>
								<Typography gutterBottom variant="h5" component="div">
									Apply for Jobs
								</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Card>
						<CardActionArea component={Link} to="/uploadresume">
							<CardMedia
								component="img"
								alt="Upload Resume"
								height="140"
								image="/images/upload-resume.jpg" 
								title="Upload Resume"
							/>
							<CardContent>
								<Typography gutterBottom variant="h5" component="div">
									Upload Updated Resume
								</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Card>
						<CardActionArea component={Link} to="/uploadcoverletter">
							<CardMedia
								component="img"
								alt="Upload Cover Letter"
								height="140"
								image="/images/upload-coverletter.jpg" 
								title="Upload Cover Letter"
							/>
							<CardContent>
								<Typography gutterBottom variant="h5" component="div">
									Upload Updated Cover Letter
								</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>
			</Grid>
		</StyledContainer>
	);
}

export default Home;
