import React from "react";
import { FormControl, InputLabel, MenuItem, Select as MuiSelect } from "@mui/material";

function SelectComponent({ question, value, onChange }) {
    const handleChange = (event) => {
        onChange(event.target.value);
    };

    const options = question.Options.split(";").map((option, index) => (
        <MenuItem key={index} value={option.trim()}>
            {option.trim()}
        </MenuItem>
    ));

    return (
        <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel>{question.Question}</InputLabel>
            <MuiSelect
                value={value || ""}
                label={question.Question}
                onChange={handleChange}
            >
                {options}
            </MuiSelect>
        </FormControl>
    );
}

export default SelectComponent;
