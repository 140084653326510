import React, { useState } from "react";
import { TextField, Chip, Paper, Typography, Box } from "@mui/material";
import { styled } from "@mui/system";

const StyledPaper = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(2),
	marginTop: theme.spacing(1),
}));

const MultipleAnswers = ({ question, value, onChange }) => {
	const [inputValue, setInputValue] = useState("");

	const handleAddAnswer = () => {
		if (inputValue.trim() !== "") {
			const newAnswers = inputValue.split(",").map(answer => answer.trim()).filter(answer => answer !== "");
			const updatedValue = value ? [...value, ...newAnswers] : newAnswers;
			onChange(updatedValue);
			setInputValue("");
		}
	};

	const handleDeleteAnswer = (indexToDelete) => {
		const updatedValue = value.filter((_, index) => index !== indexToDelete);
		onChange(updatedValue);
	};

	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			handleAddAnswer();
			event.preventDefault();
		}
	};

	return (
		<StyledPaper elevation={3}>
			<Typography variant="h6">{question.Question}</Typography>
			<Typography variant="body2" color="textSecondary">
				{question.example}
			</Typography>
			<TextField
				fullWidth
				variant="outlined"
				label="Add Answer"
				value={inputValue}
				onChange={(e) => setInputValue(e.target.value)}
				onKeyPress={handleKeyPress}
				margin="normal"
			/>
			<Box mt={2} display="flex" flexWrap="wrap">
				{value && value.map((answer, index) => (
					<Chip
						key={index}
						label={answer}
						onDelete={() => handleDeleteAnswer(index)}
						color="primary"
						variant="outlined"
						style={{ margin: '5px' }}
					/>
				))}
			</Box>
		</StyledPaper>
	);
};

export default MultipleAnswers;
